<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes">
    <form @submit.prevent="submit()" autocomplete="off" id="AddCryptoToPackForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="crypto">Crypto *</label>
            <VueNextSelect
              class="form-control"
              v-model="crypto"
              :options="cryptosAvailablesList"
              label-by="symbol"
              searchable="true"
              clear-on-close="true"
              close-on-select
            >
              <template #label="{ selected }">
                <template v-if="selected">
                  <div><img class="mr-2" width="20" :src="selected.img"/>{{ selected.symbol }} - {{ selected.name }}</div>
                </template>
                <template v-else>Select option</template>
              </template>
              <template #dropdown-item="{ option }">
                <div><img class="mr-2" width="20" :src="option.img"/>{{ option.symbol }} - {{ option.name }}</div>
              </template>
            </VueNextSelect>
            <div v-if="v$.crypto.$dirty">
              <small class="form-text text-danger" v-if="v$.crypto.required.$invalid">Required Field</small>
            </div>
          </div>
        </div>
        <div class="col-12">
            <label for="purchasePrice">Purchase price *</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text">$</div>
              </div>
              <input min="0" step="any" type="number" class="form-control" id="purchasePrice" v-model.trim.lazy="v$.purchasePrice.$model">
            </div>
            <div v-if="v$.purchasePrice.$dirty">
              <small class="form-text text-danger" v-if="v$.purchasePrice.required.$invalid">Required Field</small>
              <small class="form-text text-danger" v-if="v$.purchasePrice.numeric.$invalid">Value must be a number</small>
              <small class="form-text text-danger" v-if="v$.purchasePrice.minValue.$invalid">Value must be greater than 0</small>
            </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="quantity">Quantity *</label>
            <input min="0" step="any" type="number" class="form-control" id="quantity" v-model.trim.lazy="v$.quantity.$model">
            <div v-if="v$.quantity.$dirty">
              <small class="form-text text-danger" v-if="v$.quantity.required.$invalid">Required Field</small>
              <small class="form-text text-danger" v-if="v$.quantity.numeric.$invalid">Value must be a number</small>
              <small class="form-text text-danger" v-if="v$.quantity.minValue.$invalid">Value must be greater than 0</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <button class="btn btn-primary float-right text-center justify-content-center" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
            <span v-if="!loading">ADD</span>
            <img v-if="loading" src="@/assets/loader.svg" height="20" alt="">
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>

import Modal from '../../../Shared/Modal.vue';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required, numeric, minValue } from '@vuelidate/validators'
import VueNextSelect from 'vue-next-select';

export default {
  name: 'ClientDetail',
  props: [
    'id',
    'modalTitle',
    'class',
    'idPack',
    'cryptocurrencies'
  ],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      classes: this.class,
      cryptosAvailablesList: [],
      crypto: '',
      quantity: 0,
      purchasePrice: 0,
      loading: false
    };
  },
  created() {
    axios.get("/cryptos/availables-cryptos")
      .then((response)=> {
        this.cryptosAvailablesList = response.data.body;
      });
  },
  validations() {
    return {
      crypto: {
        required,
      },
      quantity: {
        required,
        numeric,
        minValue: minValue(-0.9999999999),
      },
      purchasePrice: {
        required,
        numeric,
        minValue: minValue(-0.9999999999)
      },
    }
  },
  components: {
    Modal,
    VueNextSelect
  },
  methods: {
    submit() {
      this.loading = true;
      const bodyToSend = [];

      this.cryptocurrencies.forEach((elem)=>{
        bodyToSend.push({
          id: elem.id,
          id_crypto: elem.ID,
          owned: elem.owned,
          purchase_price: elem.purchase_price
        });
      })


      bodyToSend.push({
        id: '',
        id_crypto: this.crypto.ID,
        owned: this.quantity,
        purchase_price: this.purchasePrice
      });
      console.log(bodyToSend);
      axios.put(`/cryptos/packs/crypto?id_pack=${this.idPack}`, bodyToSend)
        .then(()=> {
          this.loading = false;
          this.clearForm();
          this.closeModal();
          this.$emit('cryptoAddedToPack');
        })
    },
    clearForm(){
      this.crypto = '';
      this.quantity = 0;
      this.purchasePrice = 0;
    },
    closeModal(){
      document.querySelector('#AddCryptoToPack .close').click();
    }
  }
};
</script>

<style>
  legend{
    width:auto;
  }
</style>